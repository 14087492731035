import React from 'react'
import'./style.css'

function MenteesSection() {
  return (
    <div className='container mantees'>
        hello
    </div>
  )
}

export default MenteesSection