const aboutNumberData = [
    {
        title:'Course Enrolled',
        number:'5000+'
    },
    {
        title:'Placed',
        number:'400+'
    },
    {
        title:'Tied up companies',
        number:'300+'
    },
    {
        title:'Batch completed',
        number:'200+'
    }
]
export default aboutNumberData;