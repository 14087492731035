import './style.css';
import ScratchCard from 'simple-scratch-card';
import coverImage from './scratch card.png'; // Ensure the file name matches the exact name in your project

const ScratchCardComponent = () => {
  return (
    <section className="container-fluid p-3 p-md-5">
      <div className="row">
        <div className="col-md-6">
          <ScratchCard
            cover={coverImage}
            percent={50}
            width={300}
            height={300}
            onComplete={() => {
              // Code to run when the scratch is complete
              console.log("Scratch card completed!");
            }}
          >
            <div className="card-content">
              {/* Add your custom content here */}
              Your card content
            </div>
          </ScratchCard>
        </div>
      </div>
    </section>
  );
};

export default ScratchCardComponent;
